import React  from "react";
import CartTemplate from "../carts/carttemplate";
import { List } from "antd";

const OrderList = ({products}) => {
  return (
    <List
      dataSource={products}
      renderItem={(product) => (
        <List.Item
          key={product.id}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "10px 0",
            borderRadius: "8px",
          }}
        >
          <CartTemplate formValues={product} />
        </List.Item>
      )}
    />
  );
};

export default OrderList;
