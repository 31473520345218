import React, { useState, useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "../config.js";

import { Card } from "antd";
const LoginForm = ({ setIsLoggedIn, setCartItemsCount, title }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm(); // 创建表单实例
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [loginButtonDisabled, setLoginButtonDisabled] = useState(true);
  const [buttonEnableClick, setButtonEnableClick] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      message.success("You have already logged in");
      navigate("/");
    }
  }, [navigate]);

  const onGetVerification = () => {
    const email = form.getFieldValue("email");
    if (verificationLoading) return; //如果正在发送验证码，返回
    if (!email) {
      message.error("Please enter an email address.");
      return;
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailPattern.test(email)) {
      message.error("Please enter a valid email address.");
      return;
    }
    setVerificationLoading(true);
    axios
      .post(`${config.baseUrl}/api/users/send_verification_code/`, { email })
      .then((response) => {
        setVerificationLoading(false);
        if (response.data.success) {
          setButtonEnableClick(true); // 禁用验证按钮
          setLoginButtonDisabled(false); //启动登录按钮
          message.success("Verification code sent");
        } else {
          message.error(
            "Failed to send verification code.Please contact our CS by email"
          );
          setButtonEnableClick(false); // 禁用按钮
        }
      })
      .catch((error) => {
        setVerificationLoading(false);
        setButtonEnableClick(false); // 禁用按钮
        message.error(
          "Failed to send verification code.Please contact our CS by email"
        );
        console.error(error);
      });
  };

  const onFinish = (values) => {
    const verificationCode = form.getFieldValue("verificationCode");
    if (loading) return; //如果正在登录，返回
    if (!verificationCode) {
      message.error("Please enter the verification code.");
      return;
    }
    if (verificationCode.length !== 6) {
      message.error("Verification code must be 6 digits.");
      return;
    }
    setLoading(true);
    axios
      .post(`${config.baseUrl}/api/users/verify_code_and_login/`, {
        email: values.email,
        code: values.verificationCode,
      })
      .then((response) => {
        if (response.data.success) {
          message.success("Login successful");
          // Store the token and refresh token
          localStorage.setItem("accessToken", response.data.access);
          localStorage.setItem("refreshToken", response.data.refresh);
          setIsLoggedIn(true);
          navigate("/"); // Redirect to the home page
        } else {
          message.error("Login failed");
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error("An error occurred");
        navigate("/"); // Redirect to the home page
      });
  };

  return (
    <Card
      title={<h1>{title}</h1>}
      style={{ width: "80%", margin: "0 auto", marginTop: "2em" }}
    >
      <Form
        name="login"
        form={form}
        onFinish={onFinish}
        initialValues={{ remember: true }}
        style={{ fontSize: "1.2em" }}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              type: "email",
              required: true,
              message: "Please enter a valid email address!",
            },
          ]}
        >
          <Input style={{ height: "48px" }} placeholder="your@email.com" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            loading={verificationLoading}
            onClick={onGetVerification}
            style={{ height: "48px", float: "right" }}
            disabled={buttonEnableClick}
          >
            Get Verification Code
          </Button>
        </Form.Item>

        <Form.Item
          name="verificationCode"
          label="Verification Code"
          rules={[{ required: true }]}
        >
          <Input
            placeholder="123456"
            style={{ height: "48px" }}
            disabled={!buttonEnableClick}
          />
        </Form.Item>

        <Form.Item>
          <Button
            disabled={loginButtonDisabled}
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ width: "100%", height: "48px", fontSize: "16px" }}
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default LoginForm;
