import React, { useState, useEffect } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { List, Spin } from "antd";
import CartTemplate from "./carttemplate";
import axios from "axios";
import config from "../config";
import { Button, Popconfirm, message } from "antd";
import { useNavigate } from "react-router-dom";
import { Card } from "antd";

const Cart = ({ setCartItemsCount }) => {
  const [products, setProducts] = useState([]); // State to hold the array of products
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [loading, setLoading] = useState(true); // 新增加载状态
  const navigate = useNavigate();
  // 在组件挂载时发起请求
  useEffect(() => {
    const fetchStates = async () => {
      setLoading(true); // 开始加载
      try {
        const token = localStorage.getItem("accessToken");
        axios
          .get(`${config.baseUrl}/api/carts`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            const count = response.data.length;
            if (count > 0) {
              setProducts(response.data);
              setCartItemsCount(count);
            } else {
              setCartItemsCount(count);
            }
          })
          .catch((error) => {
            message.error("Failed to fetch the task", error);
            // navigate("/"); // Redirect to the home page
          });
      } catch (error) {
        navigate("/"); // Redirect to the home page
      } finally {
        setLoading(false); // 结束加载
      }
    };

    fetchStates(); // 调用函数发起请求
  }, [shouldRefetch, setCartItemsCount, navigate]); // 空依赖数组，确保只在组件挂载时执行一次

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("accessToken");
      axios
        .delete(`${config.baseUrl}/api/carts/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 204) {
            message.success("Cart deleted successfully");
            setShouldRefetch((prev) => !prev); // 更新 shouldRefetch 状态以重新加载数据
            setCartItemsCount((prev) => prev - 1);
          }
        })
        .catch((error) => {
          navigate("/"); // Redirect to the home page
        });
    } catch (error) {
      navigate("/"); // Redirect to the home page
    }
  };
  const handleCheckout = () => {
    navigate("/checkout");
  };

  return (
    <Card
      title={
        <span style={{ fontSize: "30px", fontWeight: "bold" }}>Carts</span>
      }
      style={{ width: "93%", maxWidth: 1200, margin: "15px auto" }}
    >
      <Spin spinning={loading} tip="加载中...">
        <List
          dataSource={products}
          renderItem={(product) => (
            <List.Item
              key={product.id}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "10px 0",
                borderRadius: "8px",
              }}
            >
              <CartTemplate formValues={product} />
              <Popconfirm
                title="Delete"
                description="Are you sure you want to delete this item?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleDelete(product.id)}
              >
                <Button
                  danger
                  type="primary"
                  icon={<DeleteOutlined />}
                  shape="round"
                  style={{
                    boxShadow: "0 2px 4px rgba(255, 77, 79, 0.2)",
                    transition: "all 0.3s",
                    border: "none",
                    borderRadius: "20px",
                    padding: "8px 16px",
                    width: "120px",
                    marginTop: "15px",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  Delete
                </Button>
              </Popconfirm>
            </List.Item>
          )}
        />

        {products.length > 0 && (
          <Button
            type="primary"
            style={{ width: "100%", height: "50px", fontSize: "16px" }}
            onClick={handleCheckout}
          >
            Checkout
          </Button>
        )}
      </Spin>
    </Card>
  );
};
export default Cart;
