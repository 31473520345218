import React from "react";
import { Card, Typography, Rate, Button, Space } from "antd";
import { Carousel } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import config from "../config";
import { message } from "antd";
import { Spin } from "antd"; // Added import for Spin component
const { Title, Text } = Typography;

const Product = ({ product }) => {
  const navigate = useNavigate();
  return (
    <Card style={{ width: 300 }} hoverable>
      <Carousel autoplay style={{ marginBottom: 16 }}>
        {product.image && (
          <div>
            <img
              src={product.image}
              alt="product 1"
              style={{ width: "100%", objectFit: "cover", borderRadius: 10 }}
            />
          </div>
        )}
        {product.image1 && (
          <div>
            <img
              src={product.image1}
              alt="product 2"
              style={{ width: "100%", objectFit: "cover", borderRadius: 10 }}
            />
          </div>
        )}
        {product.image2 && (
          <div>
            <img
              src={product.image2}
              alt="product 3"
              style={{ width: "100%", objectFit: "cover", borderRadius: 10 }}
            />
          </div>
        )}
        {product.image3 && (
          <div>
            <img
              src={product.image3}
              alt="product 4"
              style={{ width: "100%", objectFit: "cover", borderRadius: 10 }}
            />
          </div>
        )}
      </Carousel>
      <Title level={5}>{product.name}</Title>

      <Space direction="horizontal" align="center" style={{ marginBottom: 16 }}>
        <Text strong style={{ fontSize: 24, color: "#1890ff" }}>
          ${product.price}
        </Text>
        <Rate disabled defaultValue={product.rating} />
      </Space>
      <ul style={{ paddingLeft: 20, marginBottom: 16, lineHeight: "3" }}>
        <li>
          <Text type="secondary">Scannable Barcodes</Text>
        </li>
        <li>
          <Text type="secondary">UV, OVI & Raised Text</Text>
        </li>
        <li>
          <Text type="secondary">Perforations & Microprint</Text>
        </li>
        <li>
          <Text type="secondary">4 Week Turnaround</Text>
        </li>
      </ul>
      <Space>
        <Button onClick={() => navigate(`/products/${product.id}`)}>
          View Details
        </Button>
        <Button type="primary" onClick={() => navigate("/orderform")}>
          Order Now
        </Button>
      </Space>
    </Card>
  );
};

const ProductCard = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${config.baseUrl}/api/products`);
        setProducts(response.data);
      } catch (error) {
        message.error("Failed to fetch products");
      } finally {
        setLoading(false);
      }
    };
    fetchProducts();
  }, []);

  return (
    <Card
      title={
        <span style={{ fontSize: "30px", fontWeight: "bold" }}>Products</span>
      }
      style={{ width: "93%", maxWidth: 1200, margin: "15px auto" }}
    >
      {loading ? (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <Spin size="large" />
          <p>Loading products...</p>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            marginTop: "20px",
            backgroundColor: "white",
            borderRadius: "10px",
          }}
        >
          {products.map((product) => (
            <div key={product.id} style={{ margin: "20px 20px 20px 0" }}>
              <Product product={product} />
            </div>
          ))}
        </div>
      )}
    </Card>
  );
};

export default ProductCard;
