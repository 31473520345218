import React from "react";
import { Card, Typography, Divider, List, Button, message } from "antd";
import { CopyOutlined } from "@ant-design/icons";
const { Text } = Typography;

const Receipt = ({ orderData }) => {
  const copyToClipboard = (text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(text)
        .then(() => message.success("Order number copied to clipboard"))
        .catch(() => message.error("Copy failed, please copy manually"));
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        message.success("Order number copied to clipboard");
      } catch (err) {
        message.error("Copy failed, please copy manually");
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <Card title="Receipt" hoverable>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Text strong>Order Number: {orderData.orderId}</Text>
        <Button
          type="link"
          icon={<CopyOutlined />}
          onClick={() => copyToClipboard(orderData.orderId)}
        >
          Copy
        </Button>
      </div>
      <Text style={{ display: "block", marginBottom: 10 }}>
        Date: {orderData.date}
      </Text>
      <Divider />
      <List
        dataSource={orderData.items}
        renderItem={(item) => (
          <List.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Text>{item.name}</Text>
              <div>
                <Text>
                  ${item.price.toFixed(2)} x {item.quantity}
                </Text>
                <Text style={{ marginLeft: 10 }}>
                  = ${(item.price * item.quantity).toFixed(2)}
                </Text>
              </div>
            </div>
          </List.Item>
        )}
      />

      <Divider />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Text>Discount:</Text>
        <Text style={{ color: "red" }}>
          -${orderData.actual_discount_amount?.toFixed(2) || "0.00"}
        </Text>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Text strong>Total:</Text>
        <Text strong>${orderData.total?.toFixed(2) || "0.00"}</Text>
      </div>

      <Divider />
      <Text style={{ display: "block", textAlign: "center" }}>
        Thank you for your purchase!
      </Text>
    </Card>
  );
};

export default Receipt;
