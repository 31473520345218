import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
} from "react-router-dom";
import { Layout, Menu, Badge, Card } from "antd";

import { useParams } from "react-router-dom";
import {
  MailOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  OrderedListOutlined,
  FormOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

import "antd/dist/reset.css";
import { Navigate } from "react-router-dom";
import LoginForm from "./LoginForm/loginform.js";
import OrderForm from "./OrderForm/orderform.js";
import CheckoutForm from "./CheckoutForm/checkoutform.js";
import Home from "./Home/home.js";
import "./App.css";
import logo from "./assets/logo.webp";
import CartForm from "./carts/cart.js";
import AddressForm from "./AddressForm/addressform.js";
import getCartsCount from "./utils.js";
import OrderDetailElement from "./OrderDetail/orderdetail.js";
import MyOrderElement from "./MyOrder/myorder.js";
import ProductDetailElement from "./ProductCard/productdetail.js";
const { Header, Content, Footer } = Layout;

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = React.useState(
    !!localStorage.getItem("accessToken")
  );
  const [cartItemsCount, setCartItemsCount] = React.useState(0);
  useEffect(() => {
    getCartsCount(setCartItemsCount);
  }, []);

  const menuItems = [
    ...(isLoggedIn
      ? [
          {
            key: "/carts",
            icon: (
              <Badge count={cartItemsCount} overflowCount={99}>
                <ShoppingCartOutlined style={{ fontSize: "16px" }} />
              </Badge>
            ),
            label: <Link to="/carts">Cart</Link>,
          },
          // 如果用户已登录，则显示订单页
          {
            key: "/orderform",
            icon: <FormOutlined />,
            label: <Link to="/orderform">Order Now</Link>,
          },
          {
            key: "/myorder",
            icon: <OrderedListOutlined />,
            label: <Link to="/myorder">My Order</Link>,
          },
          {
            key: "/logout",
            icon: <LogoutOutlined />,
            label: <Link to="/logout">Logout</Link>,
          },
        ]
      : []),
    {
      key: "/",
      icon: <HomeOutlined />,
      label: <Link to="/">Home</Link>,
    },

    {
      key: "/about",
      icon: <InfoCircleOutlined />,
      label: <Link to="/about">About</Link>,
    },
    {
      key: "/contact",
      icon: <MailOutlined />,
      label: <Link to="/contact">Contact</Link>,
    },
    ...(!isLoggedIn
      ? [
          // 如果用户未登录，则显示 Login 和 Register
          {
            key: "/login",
            icon: <SettingOutlined />,
            label: <Link to="/login">Login</Link>,
          },
          {
            key: "/register",
            icon: <SettingOutlined />,
            label: <Link to="/register">Register</Link>,
          },
        ]
      : []),
  ];

  const About = () => (
    <Card
      title={
        <span style={{ fontSize: "30px", fontWeight: "bold" }}>About Us</span>
      }
      bordered={false}
      style={{ width: "93%", maxWidth: 1200, margin: "15px auto" }}
    >
      <p style={{ fontSize: "16px", lineHeight: "1.6" }}>
        We are a professional manufacturer dedicated to producing high-quality
        fake IDs. With years of experience and expertise, we are committed to
        providing our clients with the best solutions in the industry.
      </p>
      <ul style={{ marginTop: "20px" }}>
        <li>Premium Quality Products</li>
        <li>Expert Team</li>
        <li>Innovative Security Features</li>
        <li>Guaranteed Customer Satisfaction</li>
        <li>Discreet and Secure Service</li>
      </ul>
    </Card>
  );

  const Contact = () => (
    <Card
      title={
        <span style={{ fontSize: "30px", fontWeight: "bold" }}>Contact Us</span>
      }
      bordered={false}
      style={{ width: "93%", maxWidth: 1200, margin: "15px auto" }}
    >
      <p style={{ fontSize: "16px", lineHeight: "1.6" }}>
        If you have any questions or need assistance, please don't hesitate to
        contact us. Our customer service team is always ready to help you.
      </p>
      <ul style={{ marginTop: "20px" }}>
        <li>
          Email: <a href="mailto:service@idvapor.com">service@idvapor.com</a>
        </li>
        <li>Working Hours: Monday to Sunday 9:00 AM - 6:00 PM (EST)</li>
      </ul>
    </Card>
  );
  const MyOrder = () => <MyOrderElement />;

  const OrderDetail = () => {
    const { checkout_hash } = useParams();
    return <OrderDetailElement checkout_hash={checkout_hash} />;
  };
  const ProductDetail = () => {
    const { id } = useParams();
    return <ProductDetailElement id={id} />;
  };

  const Address = () => <AddressForm />;
  const Login = ({ setIsLoggedIn, setCartItemsCount, title }) => (
    <LoginForm
      setIsLoggedIn={setIsLoggedIn}
      setCartItemsCount={setCartItemsCount}
      title={title}
    />
  );
  const Cart = ({ setIsLoggedIn, setCartItemsCount, title }) => (
    <CartForm
      setIsLoggedIn={setIsLoggedIn}
      setCartItemsCount={setCartItemsCount}
      title={title}
    />
  );
  const Checkout = ({ setCartItemsCount }) => (
    <CheckoutForm setCartItemsCount={setCartItemsCount} />
  );
  const Order = ({ cartItemsCount, setCartItemsCount }) => (
    <OrderForm
      cartItemsCount={cartItemsCount}
      setCartItemsCount={setCartItemsCount}
    />
  );
  const Logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setIsLoggedIn(false);
    navigate("/");
  };

  useEffect(() => {
    // 在组件挂载时设置网站标题
    document.title = "IDVAPOR.COM - Professional ID Manufacturer";
  }, []);

  return (
    <Layout className="layout">
      <Header style={{ padding: "0" }}>
        <div className="header-content">
          <div className="logo" onClick={() => navigate("/")}>
            <img
              src={logo}
              alt="Logo"
              style={{ height: "64px", float: "left", cursor: "pointer" }}
            />
            <h1
              style={{
                display: "inline",
                color: "white",
                marginLeft: "10px",
                cursor: "pointer",
              }}
            >
              IDVAPOR
            </h1>
          </div>
          <Menu
            theme="dark"
            mode="horizontal"
            selectedKeys={[location.pathname]}
            items={menuItems}
            style={{ marginLeft: "50%" }}
          />
        </div>
      </Header>
      <Content>
        <div className="site-layout-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route
              path="/orderform"
              element={
                <Order
                  cartItemsCount={cartItemsCount}
                  setCartItemsCount={setCartItemsCount}
                />
              }
            />
            <Route path="/contact" element={<Contact />} />
            <Route
              path="/login"
              element={
                <Login
                  setIsLoggedIn={setIsLoggedIn}
                  setCartItemsCount={setCartItemsCount}
                  title="Login In"
                />
              }
            />
            <Route
              path="/carts"
              element={
                <Cart
                  setIsLoggedIn={setIsLoggedIn}
                  setCartItemsCount={setCartItemsCount}
                  title="Cart"
                />
              }
            />
            <Route
              path="/register"
              element={<Login setIsLoggedIn={setIsLoggedIn} title="Register" />}
            />
            <Route path="/address" element={<Address />} />
            <Route
              path="/checkout"
              element={<Checkout setCartItemsCount={setCartItemsCount} />}
            />
            <Route
              path="/orderdetail/:checkout_hash"
              element={<OrderDetail />}
            />
            <Route path="/products/:id" element={<ProductDetail />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/myorder" element={<MyOrder />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </Content>
      <Footer style={{ textAlign: "center", fontSize: "12px" }}>
        ©2024 Created by IDVAPOR
      </Footer>
    </Layout>
  );
};

const WrappedApp = () => (
  <Router>
    <App />
    <SpeedInsights />
    <Analytics />
  </Router>
);

export default WrappedApp;

// TODO: https://github.com/zammad/zammad
// hi@idvapor.com email service to build
