
import config from './config.js';
import axios from 'axios';
const getCartsCount = (setCartItemsCount) => {
  try {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken == null) {
      return
    }

    if (setCartItemsCount == null) {
      alert('missing setCartItems function')
    }
    const token = localStorage.getItem('accessToken')
    axios.get(`${config.baseUrl}/api/carts`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    }).then((response) => {
      //length
      const count = response.data.length;
      setCartItemsCount(count);

    }).catch((error) => {
      console.error('Order submission failed:', error);
    });
  } catch (error) {
    console.error('Error fetching states:', error);
  }
}

export default getCartsCount;
