import React from "react";
import { Card, Typography, Spin, message } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../config";
import { Carousel, Divider, Button } from "antd";
import { useNavigate } from "react-router-dom";
import "./ProductDetail.css";
import { List } from "antd";
import Price from "./Price";

const ProductDetail = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [sliders, setSliders] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          `${config.baseUrl}/api/products/${id}`
        );
        setSliders(response.data[0]);
        setDescriptions(response.data[0].descriptions);
      } catch (error) {
        message.error("Failed to get product details");
      } finally {
        setLoading(false);
      }
    };
    fetchProduct();
  }, [id]);

  if (loading) {
    return <Spin size="large" />;
  }

  if (!sliders) {
    return <Typography.Text>No product found</Typography.Text>;
  }

  return (
    <Card
      className="product-detail-card"
      title={
        <span style={{ fontSize: "30px", fontWeight: "bold" }}>
          {sliders.name}
        </span>
      }
      style={{
        width: "93%",
        maxWidth: 1200,
      }}
    >
      <Carousel autoplay>
        {sliders.image && (
          <div>
            <img
              src={sliders.image}
              alt={sliders.name}
              style={{ width: "100%", objectFit: "cover", borderRadius: 10 }}
            />
          </div>
        )}

        {sliders.image1 && (
          <div>
            <img
              src={sliders.image1}
              alt={sliders.name}
              style={{ width: "100%", objectFit: "cover", borderRadius: 10 }}
            />
          </div>
        )}
        {sliders.image2 && (
          <div>
            <img
              src={sliders.image2}
              alt={sliders.name}
              style={{ width: "100%", objectFit: "cover", borderRadius: 10 }}
            />
          </div>
        )}
        {sliders.image3 && (
          <div>
            <img
              src={sliders.image3}
              alt={sliders.name}
              style={{ width: "100%", objectFit: "cover", borderRadius: 10 }}
            />
          </div>
        )}
      </Carousel>
      <Divider />
      <List
        size="large"
        header={
          <div style={{ color: "black", fontWeight: "bold" }}>Description</div>
        }
        bordered
        dataSource={descriptions}
        renderItem={(item) => <List.Item>{item.description}</List.Item>}
      />
      <Price />

      <Button
        type="primary"
        onClick={() => navigate("/orderform")}
        style={{ marginTop: "20px", width: "100%", height: 45 }}
      >
        Order Now
      </Button>
    </Card>
  );
};

export default ProductDetail;
