import React from "react";
import { Card, QRCode, Button, message, Typography } from "antd";
import { CopyOutlined } from "@ant-design/icons";

const { Text } = Typography;

const BitcoinPayment = ({ bitcoinAddress }) => {
  const handleCopy = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(bitcoinAddress)
        .then(() => message.success("Bitcoin address copied to clipboard"))
        .catch(() => message.error("Copy failed, please copy manually"));
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = bitcoinAddress;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        message.success("Bitcoin address copied to clipboard");
      } catch (err) {
        message.error("Copy failed, please copy manually");
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <Card title="Bitcoin Payment" style={{ width: "100%", margin: "0 auto" }} hoverable>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <Text
          style={{
            width: "calc(100% - 80px)",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {bitcoinAddress}
        </Text>
        <Button icon={<CopyOutlined />} onClick={handleCopy}>
          Copy
        </Button>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <QRCode value={`bitcoin:${bitcoinAddress}`} size={200} />
      </div>
    </Card>
  );
};

export default BitcoinPayment;
