import React from "react";
import { Form, Input, Select, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { Divider } from "antd";
import { List } from "antd";
import { message } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../config.js";
const { Option } = Select;

// 示例的美国各州数据
const states = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
  { value: "DC", label: "District of Columbia" },
];

const AddressForm = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const handleBackCheckout = () => {
    navigate("/checkout");
  };

  const [addresses, setAddresses] = useState([]);

  const handleDeleteAddress = async (addressId) => {
    try {
      await axios.delete(
        `${config.baseUrl}/api/shipping_address/${addressId}/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      message.success("地址删除成功");
      // 更新地址列表
      setAddresses(addresses.filter((address) => address.id !== addressId));
    } catch (error) {
      console.error("删除地址出错:", error);
      message.error("删除地址失败，请稍后重试");
    }
  };
  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await axios.get(
          `${config.baseUrl}/api/shipping_address`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        setAddresses(response.data);
      } catch (error) {
        console.error("获取地址列表出错:", error);
        message.error("获取地址列表失败，请稍后重试");
      }
    };
    fetchAddresses();
  }, []);

  const handleSubmitAddress = async (values) => {
    try {
      const response = await axios.post(
        `${config.baseUrl}/api/shipping_address/create/`,
        {
          shipping_fullname: values.shipping_fullname,
          shipping_address: `${values.shipping_address}`,
          shipping_city: values.shipping_city,
          shipping_state: values.shipping_state,
          shipping_zip: values.shipping_zip,
          shipping_country: "US",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      if (response.status === 201) {
        message.success("Address added successfully");
        form.resetFields();
        // 刷新地址列表
        const updatedAddresses = await axios.get(
          `${config.baseUrl}/api/shipping_address`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        setAddresses(updatedAddresses.data);
      }
    } catch (error) {
      console.error("Add address failed, please try again later", error);
      message.error("Add address failed, please try again later");
    }
  };

  const onFinish = (values) => {
    handleSubmitAddress(values);
  };

  return (
    <div
      style={{
        width: "93%",
        margin: "15px auto",
        backgroundColor: "white",
        borderRadius: "10px",
        padding: "10px 0",
      }}
    >
      <div>
        <h1 style={{ paddingLeft: "20px", paddingTop: "10px" }}>Add Address</h1>
        <Divider style={{ marginTop: "10px" }} />
      </div>
      <div style={{ width: "93%", margin: "0 auto" }}>
        <List
          itemLayout="horizontal"
          dataSource={addresses}
          style={{ maxWidth: 600, margin: "0 auto" }}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  type="link"
                  danger
                  onClick={() => handleDeleteAddress(item.id)}
                >
                  Delete
                </Button>,
              ]}
            >
              <List.Item.Meta
                title={item.name}
                description={`${item.shipping_fullname}, ${item.shipping_address}, ${item.shipping_city}, ${item.shipping_state}, ${item.shipping_zip}, ${item.shipping_country}`}
              />
            </List.Item>
          )}
        />
        <Divider />
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          style={{ maxWidth: 600, margin: "0 auto" }}
        >
          <Form.Item
            label="Full Name"
            name="shipping_fullname"
            rules={[{ required: true, message: "Please enter your full name" }]}
          >
            <Input placeholder="Enter your full name" />
          </Form.Item>

          <Form.Item
            label="Street Address"
            name="shipping_address"
            rules={[
              { required: true, message: "Please enter your street address" },
              { max: 70, message: "Address cannot exceed 70 characters" },
            ]}
          >
            <Input placeholder="Enter your street address (max 70 characters)" />
          </Form.Item>

          <Form.Item
            label="City"
            name="shipping_city"
            rules={[{ required: true, message: "Please enter your city" }]}
          >
            <Input placeholder="Enter your city" />
          </Form.Item>

          <Form.Item
            label="State"
            name="shipping_state"
            rules={[{ required: true, message: "Please select your state" }]}
          >
            <Select placeholder="Select your state">
              {states.map((state) => (
                <Option key={state.value} value={state.value}>
                  {state.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Zip Code"
            name="shipping_zip"
            rules={[
              {
                required: true,
                message: "Please enter your zip code xxxxx or xxxxx-xxxx",
              },
              {
                pattern: /^\d{5}(-\d{4})?$/,
                message: "Invalid zip code format",
              },
            ]}
          >
            <Input placeholder="Enter your zip code xxxxx or xxxxx-xxxx" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Submit
            </Button>
            <Button
              type="primary"
              style={{ width: "100%", marginTop: "10px" }}
              onClick={handleBackCheckout}
            >
              Back Checkout
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddressForm;
