import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  message,
  Space,
  Typography,
  Tag,
  QRCode,
  Divider,
  Spin,
} from "antd";
import { CopyOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../config";
import { useNavigate } from "react-router-dom";

const { Text, Title } = Typography;

const MyOrder = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${config.baseUrl}/api/myorders`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setOrders(response.data.orders);
        } else {
          message.error("Failed to fetch orders");
        }
      })
      .catch((error) => {
        navigate("/login");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [navigate]);

  const copyOrderNumber = (orderNumber) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(orderNumber)
        .then(() => {
          message.success("Order number copied to clipboard");
        })
        .catch(() => {
          message.error("Copy failed, please copy manually");
        });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = orderNumber;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        message.success("Order number copied to clipboard");
      } catch (err) {
        message.error("Copy failed, please copy manually");
      }
      document.body.removeChild(textArea);
    }
  };

  const OrderCard = ({ order }) => (
    <Card
      key={order.key}
      hoverable
      style={{
        marginBottom: 24,
        width: "100%",
        maxWidth: 1200,
        margin: "0 auto 24px",
      }}
    >
      <Row gutter={[16, 16]} align="middle">
        <Col span={24}>
          <Space>
            <Title level={5} style={{ margin: 0 }}>
              Order ID: {order.order_number}
            </Title>
            <Button
              icon={<CopyOutlined />}
              onClick={() => copyOrderNumber(order.order_number)}
            />
            <Tag
              color={
                order.order_status === "Pending Payment" ? "volcano" : "green"
              }
              style={{ marginLeft: "auto" }}
            >
              {order.order_status}
            </Tag>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Text strong>Shipping Address:</Text>
          <br></br>
          <Text>{order.shipping_address}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Order Date:</Text>
          <br></br>
          <Text>{order.date}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Payment Status:</Text>
          <br></br>
          <Text>{order.payment_status}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Payment Method:</Text>
          <br></br>
          <Text>{order.payment_method}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Discount Amount:</Text>
          <br></br>
          <Text>${order.actual_discount_amount.toFixed(2)}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Total After Discount:</Text>
          <br></br>
          <Text>${order.order_total.toFixed(2)}</Text>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[16, 16]} justify="space-between" align="middle">
        <Col>
          <Space>
            <Button
              type="primary"
              onClick={() => navigate(`/orderdetail/${order.checkout_hash}`)}
            >
              Order Details
            </Button>
          </Space>
        </Col>
        <Col>
          <QRCode
            value={order.payment_address}
            iconSize={40}
            size={120}
            bordered={false}
            errorLevel="H"
            color="#1890ff"
          />
        </Col>
      </Row>
    </Card>
  );

  return (
    <Card
      title={
        <Title level={2} style={{ marginBottom: "0" }}>
          My Orders
        </Title>
      }
      style={{ width: "93%", maxWidth: 1200, margin: "15px auto" }}
    >
      <Spin spinning={loading} tip="Loading...">
        {orders.map((order) => (
          <React.Fragment key={order.key}>
            <OrderCard order={order} style={{ marginBottom: "16px" }} />
            <Divider />
          </React.Fragment>
        ))}
      </Spin>
    </Card>
  );
};

export default MyOrder;
