import React from "react";
import { Col, Row } from "antd";
import logo from "../assets/logo.webp";
import { Divider } from "antd";

const CartTemplate = ({ formValues }) => {
  // 添加一个函数来格式化日期
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  return (
    <div>
      <div
        style={{
          textAlign: "left",
          borderRadius: "10px",
          border: "1px solid lightblue",
          padding: "10px",
          margin: "10px auto",
        }}
      >
        <Row style={{ backgroundColor: "lightblue" }}>
          <Col span={24}>
            <div
              style={{
                color: "white",
                lineHeight: "30px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {formValues.product["name"]}
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Col span={8}>
            {/*
  左侧的头像跟签名
  */}
            <div style={{ textAlign: "center" }}>
              <img
                src={formValues.upload_picture || logo} // 图像URL
                alt="User Avatar"
                style={{ width: "80px", height: "80px" }} // 设置图像大小和形状
              />
            </div>

            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <img
                src={formValues.upload_signature || logo} // 图像URL
                alt="User Avatar"
                style={{ width: "80px", height: "80px" }} // 设置图像大小和形状
              />
            </div>
          </Col>
          <Col span={16}>
            {/* 右侧的客户资料 */}
            <Row gutter={16}>
              {" "}
              {/* gutter 可以用来设置列之间的间距 */}
              <Col span={8}>
                {/* 第一个客户资料部分 */}
                <div>
                  <p>FN</p>
                  <h3>{formValues.first_name}</h3>
                </div>
              </Col>
              <Col span={8}>
                {/* 第二个客户资料部分 */}
                <div>
                  <p>MN</p>
                  <h3>{formValues.middle_name}</h3>
                </div>
              </Col>
              <Col span={8}>
                {/* 第三个客户资料部分 */}
                <div>
                  <p>LN</p>
                  <h3>{formValues.last_name}</h3>
                </div>
              </Col>
            </Row>

            <Divider dashed style={{ margin: "5px auto" }} />

            <Row gutter={16}>
              {" "}
              {/* gutter 可以用来设置列之间的间距 */}
              <Col span={8}>
                {/* 第一个客户资料部分 */}
                <div>
                  <p>HAIR</p>
                  <h3>{formValues.hair}</h3>
                </div>
              </Col>
              <Col span={8}>
                {/* 第二个客户资料部分 */}
                <div>
                  <p>EYES</p>
                  <h3>{formValues.eyes}</h3>
                </div>
              </Col>
              <Col span={8}>
                {/* 第三个客户资料部分 */}
                <div>
                  <p>Gender</p>
                  <h3>{formValues.gender}</h3>
                </div>
              </Col>
            </Row>

            <Divider dashed style={{ margin: "5px auto" }} />

            <Row gutter={16}>
              {" "}
              {/* gutter 可以用来设置列之间的间距 */}
              <Col span={8}>
                {/* 第一个客户资料部分 */}
                <div>
                  <p>DOB</p>
                  <h3 style={{ fontSize: "10px", fontWeight: "bold" }}>
                    {formatDate(formValues.dob)}
                  </h3>
                </div>
              </Col>
              <Col span={8}>
                {/* 第二个客户资料部分 */}
                <div>
                  <p>WEIGHT</p>
                  <h3>{formValues.weight}</h3>
                </div>
              </Col>
              <Col span={8}>
                {/* 第三个客户资料部分 */}
                <div>
                  <p>HEIGHT</p>
                  <h3>{formValues.height}</h3>
                </div>
              </Col>
            </Row>

            <Divider dashed style={{ margin: "5px auto" }} />

            <Row gutter={16}>
              {" "}
              {/* gutter 可以用来设置列之间的间距 */}
              <Col span={24}>
                <p style={{ textAlign: "left" }}> Address</p>
              </Col>
            </Row>
            <Row gutter={16}>
              {" "}
              {/* gutter 可以用来设置列之间的间距 */}
              <Col span={24}>
                <h3 style={{ textAlign: "left" }}>
                  {formValues.address},{formValues.product["state_name"]}{" "}
                  {formValues.zip_code}
                </h3>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CartTemplate;
