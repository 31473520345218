import { Carousel, Button } from "antd";

import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "../config";
import video1 from "../assets/1.mp4";
import video2 from "../assets/2.mp4";
import video3 from "../assets/3.mp4";
import video4 from "../assets/4.mp4";
const contentStyle = {
  width: "93%",
  margin: "0 auto",
  color: "#fff",
  textAlign: "center",
  background: "#364d79",
  borderRadius: "30px",
};

const VideoComponent = (video_name) => {
  const navigate = useNavigate();
  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <video
        autoPlay
        loop
        muted
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      >
        <source src={video_name} type="video/mp4" />
      </video>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div
          style={{
            color: "white",
            textAlign: "center",
            marginBottom: "30px",
            textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
          }}
        >
          <h1
            style={{
              fontSize: "1.6em",
              fontWeight: "bold",
              marginBottom: "15px",
              letterSpacing: "2px",
            }}
          >
            High-Quality Fake IDs
          </h1>
          <p
            style={{
              fontSize: "1em",
              fontWeight: "300",
              lineHeight: "1.5",
            }}
          >
            Safe, Reliable, Fast Delivery
          </p>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => navigate("/orderform")}
            size="large"
            type="primary"
            style={{
              fontSize: "1em",
              padding: "10px 30px",
              height: "auto",
              borderRadius: "50px",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
              transition: "all 0.3s ease",
            }}
            hover={{
              transform: "translateY(-2px)",
              boxShadow: "0 6px 8px rgba(0,0,0,0.15)",
            }}
          >
            Order Now
          </Button>
        </div>
      </div>
    </div>
  );
};
const IndexCarousel = () => {
  const [sliders, setSliders] = useState([]);
  useEffect(() => {
    const fetchSliders = async () => {
      try {
        const response = await axios.get(
          `${config.baseUrl}/api/slider_images/`
        );
        if (response.data && response.data.length > 0) {
          setSliders(response.data);
        }
      } catch (error) {}
    };

    fetchSliders();
  }, []);

  const getSliderImages = (slider) => {
    return ["image", "image1", "image2", "image3"]
      .map((key) => slider[key])
      .filter((image) => image !== null);
  };

  return (
    <>
      <Carousel
        autoplay
        style={{ backgroundColor: "white" }}
        arrows={true}
        autoplaySpeed={5000}
      >
        <div>{VideoComponent(video4)}</div>
        <div>{VideoComponent(video1)}</div>
        <div>{VideoComponent(video2)}</div>
        <div>{VideoComponent(video3)}</div>
        {sliders.flatMap((slider, sliderIndex) =>
          getSliderImages(slider).map((image, imageIndex) => (
            <div key={`${sliderIndex}-${imageIndex}`}>
              <img
                src={image}
                alt={`product ${sliderIndex + 1}-${imageIndex + 1}`}
                style={contentStyle}
              />
            </div>
          ))
        )}
      </Carousel>
    </>
  );
};

export default IndexCarousel;
