import React from "react";
import { Steps } from "antd";

const OrderStatus = ({ status = "waiting_payment" }) => {
  const orderSteps = [
    {
      key: "waiting payment",
      title: "Waiting for Payment",
      description: "Please pay for the order as soon as possible.",
    },
    {
      key: "order confirmed",
      title: "Order Confirmed",
      description: "The order has been confirmed.",
    },
    {
      key: "in production",
      title: "In Production",
      description: "The order is in production.",
    },
    {
      key: "shipping",
      title: "Shipping",
      description: "The order is in shipping.",
    },
    {
      key: "delivered",
      title: "Order Completed",
      description: "The order is completed.",
    },
  ];

  const currentStep = orderSteps.findIndex((step) => step.key === status);

  return (
    <Steps
      current={currentStep}
      direction="vertical"
      items={orderSteps.map(({ title, description }) => ({
        title,
        description,
      }))}
    />
  );
};

export default OrderStatus;
