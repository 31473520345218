import { Card, Typography, Button, Row, Col} from "antd";
import price_1 from "../assets/price_1.JPG";
import price_2 from "../assets/price_2.JPG";
import price_3 from "../assets/price_3.JPG";
import price_4 from "../assets/price_4.JPG";
import price_5 from "../assets/price_5.JPG";

const Price = () => {
  return (
    <Card
      title={
        <span style={{ fontSize: "30px", fontWeight: "bold" }}>Price</span>
      }
      bordered={false}
      style={{ width: "100%", maxWidth: 1200, margin: "15px auto" }}
    >
      <Row gutter={[6, 16]} justify="center">
        <Col xs={24} sm={12} md={4}>
          <Card
            hoverable
            style={{ textAlign: "center", height: "100%" }}
            cover={
              <img
                alt="1-2 People"
                src={price_1}
                style={{ height: 200, objectFit: "cover" }}
              />
            }
          >
            <Card.Meta
              title={<Typography.Title level={3}>1-2 People</Typography.Title>}
              description={
                <>
                  <Typography.Title level={4} type="success">
                    0 OFF
                  </Typography.Title>
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    <li>Standard quality</li>
                    <li>Basic functions</li>
                    <li>Free dumplicate</li>
                    <li>0 discount off</li>
                  </ul>
                  <Button type="primary">Order Now</Button>
                </>
              }
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={4}>
          <Card
            hoverable
            style={{ textAlign: "center", height: "100%" }}
            cover={
              <img
                alt="3-5 People"
                src={price_2}
                style={{ height: 200, objectFit: "cover" }}
              />
            }
          >
            <Card.Meta
              title={<Typography.Title level={3}>3-5 People</Typography.Title>}
              description={
                <>
                  <Typography.Title level={4} type="success">
                    10% OFF
                  </Typography.Title>
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    <li>Standard quality</li>
                    <li>Basic functions</li>
                    <li>Free dumplicate</li>
                    <li>10% discount off</li>
                  </ul>
                  <Button type="primary">Order Now</Button>
                </>
              }
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={4}>
          <Card
            hoverable
            style={{ textAlign: "center", height: "100%" }}
            cover={
              <img
                alt="6-10 People"
                src={price_3}
                style={{ height: 200, objectFit: "cover" }}
              />
            }
          >
            <Card.Meta
              title={<Typography.Title level={3}>6-10 People</Typography.Title>}
              description={
                <>
                  <Typography.Title level={4} type="success">
                    20% OFF
                  </Typography.Title>
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    <li>Standard quality</li>
                    <li>Basic functions</li>
                    <li>Free dumplicate</li>
                    <li>20% discount off</li>
                  </ul>
                  <Button type="primary">Order Now</Button>
                </>
              }
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={4}>
          <Card
            hoverable
            style={{ textAlign: "center", height: "100%" }}
            cover={
              <img
                alt="11-20 People"
                src={price_4}
                style={{ height: 200, objectFit: "cover" }}
              />
            }
          >
            <Card.Meta
              title={
                <Typography.Title level={3}>11-20 People</Typography.Title>
              }
              description={
                <>
                  <Typography.Title level={4} type="success">
                    30% OFF
                  </Typography.Title>
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    <li>Standard quality</li>
                    <li>Basic functions</li>
                    <li>Free dumplicate</li>
                    <li>30% discount off</li>
                  </ul>
                  <Button type="primary">Order Now</Button>
                </>
              }
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={4}>
          <Card
            hoverable
            style={{ textAlign: "center", height: "100%" }}
            cover={
              <img
                alt="特别套餐"
                src={price_5}
                style={{ height: 200, objectFit: "cover" }}
              />
            }
          >
            <Card.Meta
              title={<Typography.Title level={3}>21+ People</Typography.Title>}
              description={
                <>
                  <Typography.Title level={4} type="success">
                    40% OFF
                  </Typography.Title>
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    <li>Standard quality</li>
                    <li>Basic functions</li>
                    <li>Free dumplicate</li>
                    <li>40% discount off</li>
                  </ul>
                  <Button type="primary">Order Now</Button>
                </>
              }
            />
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default Price;
