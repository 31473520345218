import IndexCarousel from "../Carousel/carousel.js";
import ProductCard from "../ProductCard/productcard.js";
import Intro from "../Intro/intro.js";
import Price from "../ProductCard/Price.js";
const Home = () => (
  <>
    <IndexCarousel />
    <div style={{ width: "93%", maxWidth: 1200, margin: "15px auto" }}>
    <Price />
    </div>
    <ProductCard />
    <Intro />
  </>
);

export default Home;
