import React from "react";
import { Row, Col, Card } from "antd";
import {
  IdcardOutlined,
  CustomerServiceOutlined,
  LockOutlined,
} from "@ant-design/icons";

const Intro = () => {
  return (
    <Card
      title="IDVAPOR's Advantages"
      style={{ width: "93%", maxWidth: 1200, margin: "15px auto" }}
    >
      <div style={{ padding: "24px" }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={8}>
            <Card
              hoverable
              cover={
                <IdcardOutlined
                  style={{
                    fontSize: "48px",
                    color: "#1890ff",
                    marginTop: "24px",
                  }}
                />
              }
            >
              <Card.Meta
                title="Unmatched Reliability"
                description="We've been providing top-notch fake IDs with advanced security features for 8 years! No more worrying about getting caught with a fake ID!"
              />
            </Card>
          </Col>
          <Col xs={24} sm={8}>
            <Card
              hoverable
              cover={
                <CustomerServiceOutlined
                  style={{
                    fontSize: "48px",
                    color: "#1890ff",
                    marginTop: "24px",
                  }}
                />
              }
            >
              <Card.Meta
                title="Exceptional Support"
                description="Have questions about your order? Our support team is available 7 days a week! Reach out to us via our contact page or live chat."
              />
            </Card>
          </Col>
          <Col xs={24} sm={8}>
            <Card
              hoverable
              cover={
                <LockOutlined
                  style={{
                    fontSize: "48px",
                    color: "#1890ff",
                    marginTop: "24px",
                  }}
                />
              }
            >
              <Card.Meta
                title="Comprehensive Security Features"
                description="Our fake IDs are equipped with advanced security features, including holograms, OVI, UV/blacklight test passability, swipeable/scannable IDs with all your information, and they also withstand the bend test."
              />
            </Card>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default Intro;
